<template>
  <home-page/>
</template>
<script>
import HomePage from '@/components/HomePage';
import page from '@/plugins/mixins/page';
export default {
  components: {HomePage},
  mixins: [page],
};
</script>
